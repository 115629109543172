import Vue from "vue";

export default {
    uploadDocumentosCandidato(arquivos, inscricao) {

        let formData = new FormData();

        arquivos.forEach(file => formData.append("files", file));

        return Vue.axios.post("documentos/candidato/" + inscricao.oid, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    downloadDocumentosCandidato(inscricaoOid) {
        return Vue.axios.get("documentos/candidato", {
            params: {
                inscricaoOid: inscricaoOid
            },
            responseType: 'blob'
        });
    },

    downloadDocumentosCandidatoParaVisualizacao(inscricaoOid) {
        return Vue.axios.get("documentos/candidato/bytes", {
            params: {
                inscricaoOid: inscricaoOid
            }
        })
    },

    recuperarProcessosVisualizacaoDocumentos(){
        return Vue.axios.get("documentos/processo")
    },

    recuperarOpcoesPorProcesso(processoOid){
        return Vue.axios.get("documentos/opcao/" + processoOid)
    },

    recuperarInscricoesVisualizacaoDocumentosPorOpcao(opcaoOid) {
        return Vue.axios.get("documentos/inscricoes/" + opcaoOid)
    },

    recuperaDocumentosInfoPorInscricao(inscricaoOid) {
        return Vue.axios.get("documentos/info/incscricao/" + inscricaoOid)
    },
};